<template>
  <v-container class="ma-0 pa-0">
    <hero title="Store"></hero>
    <v-row>
      <v-col class="mt-10 ma-3 about-text">
        <p><strong>Coming Soon!</strong></p>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: 'Store',
  metaInfo: {
    title: 'Store',
    meta: [
      { vmid: 'og:title', property: 'og:title', content: 'Store'},
    ]
  },
}
</script>
<style lang="sass">
a
  text-decoration: none
.about-text
  font-size: 1.1rem
  color: #dcdcdc
</style>

